*, html, body{
    box-sizing: border-box;
}

.height-600{
  height: 600px;
}

/* width */
::-webkit-scrollbar {
    width: 7px !important;
    height: 7px !important;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: whitesmoke;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: gray;
    border-radius: 50px;
    -webkit-border-radius: 50px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    transition: all 0.4s;
    background: var(--lightPurple);
  }
  
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  [aria-disabled="true"] {
    opacity: 0.5;
  }


  .cc-w-32px{
    width: 32px !important;
  }

  .cc-h-32px{
    height: 32px !important;
  }

  .comment-input:focus, .comment-input:active {
    outline: none !important;
  }

#like_post_action:hover > *{
  color: rgb(107, 114, 128);
  fill: #7bb062;
}

#comment_post_action:hover > * , #share_post_action:hover > *{
  color: rgb(107, 114, 128);
}

.trash-red > svg:hover {
  fill: red !important;
}

.cross-white > svg:hover {
  fill: white !important;
}
